.about_inner-container {
  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  padding: 2rem;
  margin-bottom: 2rem;
}

.about-title {
  /* margin-top: 3rem; */
  margin: 0 1.5rem 3rem;
  text-align: left;
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.about-text {
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
  line-height: 1.3;
  margin: 0 1.5rem 3rem;
  text-align: left;
}

@media (max-width: 444px) {
  .about_inner-container {
    width: 95%;
    padding: 2rem .5rem;
  }
  .about-title {
    text-align: center;
    margin: 0 1.5rem 2rem;
  }
}