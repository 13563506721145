.contact_inner-container {
  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.contact-title {
  /* margin-top: 3rem; */
  margin: 0 1.5rem 1.7rem;
  text-align: left;
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.contact-textfield {
  display: block;
  border-radius: 5px;
  width: 90%;
  height: 50px;
  padding: 5px 20px;
  margin: 0 1.5rem 1.3rem;
  font-size: 1.2rem;
  line-height: 1.3;
  border-style: none;
  color: #3d3d3d;
  outline-color: rgba(255, 149, 1, 0.461);
}

.contact-textarea {
  display: block;
  border-radius: 5px;
  width: 90%;
  height: 200px;
  padding: 20px 20px;
  margin: 0 1.5rem 3rem;
  font-size: 1.2rem;
  line-height: 1.3;
  border-style: none;
  color: #3d3d3d;
  outline-color: rgba(255, 149, 1, 0.461);
}

.contact-submit {
  border-radius: 30px;
  min-width: 150px;
  min-height: 50px;
  padding: 20px 20px;
  margin: 0 1.5rem 3rem;
  font-size: 1.2rem;
  line-height: 1.3;
  border-style: none;
  color: #3d3d3d;
  background-color: rgba(255, 149, 1, 0.461);
  cursor: pointer;
  outline: none;
}

.contact-submit:hover {
  background-color: rgb(1, 255, 65);
  outline: none;
}

#sent {
  display: block;
  border-radius: 5px;
  width: 90%;
  padding: 5px 20px;
  margin: 0 1.5rem 1.3rem;
  font-size: 1.2rem;
  line-height: 50px;
  text-align: center;
  background-color: rgb(117, 241, 117);
  color: rgb(24, 85, 24);

  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: max-height .2s linear;
}

@media (max-width: 444px) {
  .contact_inner-container {
    width: 95%;
    padding: 2rem .5rem;
    text-align: center;
  }
  .contact-textfield {
    padding: 5px 12px;
    margin: 0 .1rem 1.3rem;
  }
  .contact-textarea {
    padding: 20px 10px;
    margin: 0 .1rem 3rem;
  }
  .contact-title {
    text-align: center;
  }
  #sent {
    margin: 0 .1rem 1.3rem;
    padding: 5px;
    width: 95%;
  }
}