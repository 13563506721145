body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.about_inner-container {
  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  padding: 2rem;
  margin-bottom: 2rem;
}

.about-title {
  /* margin-top: 3rem; */
  margin: 0 1.5rem 3rem;
  text-align: left;
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.about-text {
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
  line-height: 1.3;
  margin: 0 1.5rem 3rem;
  text-align: left;
}

@media (max-width: 444px) {
  .about_inner-container {
    width: 95%;
    padding: 2rem .5rem;
  }
  .about-title {
    text-align: center;
    margin: 0 1.5rem 2rem;
  }
}
.project {
  display: inline-block;
  /* border: 1px solid pink; */
  width: 33.1%;
}

.photo-overlay {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0; 
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0, .65);
}

.photo-overlay {
  opacity: 0;
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
}

.photo-overlay:hover {
  opacity: 1;
}

.photo-overlay h3 {
  font-family: 'Amatic SC', cursive;
  font-size: 1.6em;
  line-height: 1.25em;
  margin-top: .85em;
  margin-bottom: .5em;
}
.photo-overlay p {
  font-family: 'Josefin Sans', sans-serif;
  font-size: .875em;
  line-height: 1.4;
  margin: 0 0 1.5em;
}

.box {
  float: left;
  position: relative;
  height: 220px;
  /* width: calc(33.33333% - 30px); */
  /* width: 100%; */
  margin: 15px;
  /* border: 1px solid red; */
}

.box img {
  width: 100%;
  height: 100%;
}

@media (max-width: 444px) {
  .project {
    display: inline-block;
    width: 100%;
  }
}
.projects_inner-container {
  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  padding: 2rem;
  margin-bottom: 2rem;
}

.projects-title {
  /* margin-top: 3rem; */
  margin: 0 1.5rem 3rem;
  text-align: left;
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.projects_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* width: 80%; */
  /* border: 1px solid green; */
}

@media (max-width: 444px) {
  .projects_inner-container {
    width: 95%;
    padding: 2rem .5rem;
    margin-bottom: 2rem;
  }
  .projects-title {
    text-align: center;
    margin: 0 1.5rem 2rem;
  }
}
.work_inner-container {
  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.work-title {
  /* margin-top: 3rem; */
  margin: 0 1.5rem 3rem;
  
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.work-text {
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
  line-height: 1.3;
  margin: 0 1.5rem 3rem;
  
}

.work-date {
  color: rgba(85, 75, 0, 0.893);
  margin: 0 1.5rem 0.6rem;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.1rem;
}

.work-position {
  color: rgba(85, 75, 0, 0.893);
  margin: 0 1.5rem 1rem;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
}

@media (max-width: 444px) {
  .work_inner-container {
    width: 95%;
    padding: 2rem .5rem;
  }
  .work-title {
    text-align: center;
    margin: 0 1.5rem 3rem;
  }
}
.education_inner-container {
  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.education-title {
  /* margin-top: 3rem; */
  margin: 0 1.5rem 3rem;
  
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.education-text {
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
  line-height: 1.3;
  margin: 0 1.5rem 3rem;
  
}

.education-date {
  color: rgba(85, 75, 0, 0.893);
  margin: 0 1.5rem 0.6rem;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.1rem;
}

.education-subject {
  color: rgba(85, 75, 0, 0.893);
  margin: 0 1.5rem 1rem;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
}

.education-school {
  display: inline-block;
  color: rgba(85, 75, 0, 0.893);
  margin: 0 1.5rem 1rem;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  border-bottom: 1px solid rgba(85, 75, 0, 0.893);
}

@media (max-width: 444px) {
  .education_inner-container {
    width: 95%;
    padding: 2rem .5rem;
  }
  .education-title {
    text-align: center;
    margin: 0 1.5rem 2rem;
  }
}
.contact_inner-container {
  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.contact-title {
  /* margin-top: 3rem; */
  margin: 0 1.5rem 1.7rem;
  text-align: left;
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.contact-textfield {
  display: block;
  border-radius: 5px;
  width: 90%;
  height: 50px;
  padding: 5px 20px;
  margin: 0 1.5rem 1.3rem;
  font-size: 1.2rem;
  line-height: 1.3;
  border-style: none;
  color: #3d3d3d;
  outline-color: rgba(255, 149, 1, 0.461);
}

.contact-textarea {
  display: block;
  border-radius: 5px;
  width: 90%;
  height: 200px;
  padding: 20px 20px;
  margin: 0 1.5rem 3rem;
  font-size: 1.2rem;
  line-height: 1.3;
  border-style: none;
  color: #3d3d3d;
  outline-color: rgba(255, 149, 1, 0.461);
}

.contact-submit {
  border-radius: 30px;
  min-width: 150px;
  min-height: 50px;
  padding: 20px 20px;
  margin: 0 1.5rem 3rem;
  font-size: 1.2rem;
  line-height: 1.3;
  border-style: none;
  color: #3d3d3d;
  background-color: rgba(255, 149, 1, 0.461);
  cursor: pointer;
  outline: none;
}

.contact-submit:hover {
  background-color: rgb(1, 255, 65);
  outline: none;
}

#sent {
  display: block;
  border-radius: 5px;
  width: 90%;
  padding: 5px 20px;
  margin: 0 1.5rem 1.3rem;
  font-size: 1.2rem;
  line-height: 50px;
  text-align: center;
  background-color: rgb(117, 241, 117);
  color: rgb(24, 85, 24);

  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  -webkit-transition: max-height .2s linear;
  transition: max-height .2s linear;
}

@media (max-width: 444px) {
  .contact_inner-container {
    width: 95%;
    padding: 2rem .5rem;
    text-align: center;
  }
  .contact-textfield {
    padding: 5px 12px;
    margin: 0 .1rem 1.3rem;
  }
  .contact-textarea {
    padding: 20px 10px;
    margin: 0 .1rem 3rem;
  }
  .contact-title {
    text-align: center;
  }
  #sent {
    margin: 0 .1rem 1.3rem;
    padding: 5px;
    width: 95%;
  }
}
.footer_container {
  color: rgba(85, 75, 0, 0.893);
  margin-top: 3rem;
}

#footer_inner-container {
  max-height: 0;
  /* padding: 2rem; */
  overflow: hidden;
  -webkit-transition: max-height .5s linear;
  transition: max-height .5s linear;

  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  margin-bottom: 2rem;
  text-align: left;
}

.redheart {
  /* color:#ff0000; */
  
  font-size:16px;
  cursor: pointer;
}

.redheart:hover {
  color:#ff0000;
}

.bonus {
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
  line-height: 1.3;
  margin: 0 1.5rem 3rem;
}
.bonus-title {
  margin: 0 1.5rem 1.7rem;
  text-align: left;
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.video-wrapper {
	position: relative;
  height: 437px;
  width: 60%;
  margin: 4rem auto;
}
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.footer_media-logo_container .logo {
  height: 20px;
  margin: .5rem;
}

@media (max-width: 444px) {
  .bonus-title {
    text-align: center;
  }
  .bonus {
    margin: 0 auto 3rem;
  }
  .video-wrapper {
    height: 195px;
    width: 100%;
  }

}
.App {
  text-align: center;
}

.App_header {
  background: url(/static/media/greg-bg2.5c5306bb.jpg) no-repeat center center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: rgba(85, 75, 0, 0.893);
  background-size: cover;
}

.App_header-h1 {
  position: relative;
  top: 2rem;
  left: 12vw;
  /* top: calc(1vh + 2vw); */
  font-family: 'Amatic SC', cursive;
  font-size: 7rem;
  font-size: calc(3rem + 5vw);
  margin-bottom: 0;
}
.App_header-h2 {
  position: relative;
  top: 2rem;
  left: 12vw;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2rem;
  font-size: calc(1rem + 1vw);
  margin-top: .9rem;
}

.media-logo_container {
  position: relative;
  left: 12vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 15rem;
}

.media-logo {
  display: inline-block;
}

.logo {
  display: inline-block;
  height: 35px;
  margin-right: 10px;
  margin-top: 2.2rem;
}

.content_container {
  background-color: #FAFF00;
  padding: 3rem 2rem;
}

@media (max-width: 444px) {
  .App_header-h1 {
    left: 10vw;
  }
  .App_header-h2 {
    left: 10vw;
    margin-top: .5rem;
  }
  .content_container {
    padding: 2rem 0;
  }
}
