.footer_container {
  color: rgba(85, 75, 0, 0.893);
  margin-top: 3rem;
}

#footer_inner-container {
  max-height: 0;
  /* padding: 2rem; */
  overflow: hidden;
  transition: max-height .5s linear;

  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  margin-bottom: 2rem;
  text-align: left;
}

.redheart {
  /* color:#ff0000; */
  
  font-size:16px;
  cursor: pointer;
}

.redheart:hover {
  color:#ff0000;
}

.bonus {
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3rem;
  line-height: 1.3;
  margin: 0 1.5rem 3rem;
}
.bonus-title {
  margin: 0 1.5rem 1.7rem;
  text-align: left;
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.video-wrapper {
	position: relative;
  height: 437px;
  width: 60%;
  margin: 4rem auto;
}
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.footer_media-logo_container .logo {
  height: 20px;
  margin: .5rem;
}

@media (max-width: 444px) {
  .bonus-title {
    text-align: center;
  }
  .bonus {
    margin: 0 auto 3rem;
  }
  .video-wrapper {
    height: 195px;
    width: 100%;
  }

}