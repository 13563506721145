.project {
  display: inline-block;
  /* border: 1px solid pink; */
  width: 33.1%;
}

.photo-overlay {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0; 
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0, .65);
}

.photo-overlay {
  opacity: 0;
  transition: opacity .25s ease-in-out;
}

.photo-overlay:hover {
  opacity: 1;
}

.photo-overlay h3 {
  font-family: 'Amatic SC', cursive;
  font-size: 1.6em;
  line-height: 1.25em;
  margin-top: .85em;
  margin-bottom: .5em;
}
.photo-overlay p {
  font-family: 'Josefin Sans', sans-serif;
  font-size: .875em;
  line-height: 1.4;
  margin: 0 0 1.5em;
}

.box {
  float: left;
  position: relative;
  height: 220px;
  /* width: calc(33.33333% - 30px); */
  /* width: 100%; */
  margin: 15px;
  /* border: 1px solid red; */
}

.box img {
  width: 100%;
  height: 100%;
}

@media (max-width: 444px) {
  .project {
    display: inline-block;
    width: 100%;
  }
}