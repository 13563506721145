.projects_inner-container {
  width: 80%;
  margin: auto;
  background-color: #FBFE85;
  padding: 2rem;
  margin-bottom: 2rem;
}

.projects-title {
  /* margin-top: 3rem; */
  margin: 0 1.5rem 3rem;
  text-align: left;
  color: rgba(85, 75, 0, 0.893);
  font-family: 'Amatic SC', cursive;
  font-size: 3rem;
}

.projects_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* width: 80%; */
  /* border: 1px solid green; */
}

@media (max-width: 444px) {
  .projects_inner-container {
    width: 95%;
    padding: 2rem .5rem;
    margin-bottom: 2rem;
  }
  .projects-title {
    text-align: center;
    margin: 0 1.5rem 2rem;
  }
}