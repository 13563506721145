.App {
  text-align: center;
}

.App_header {
  background: url(./images/greg-bg2.jpg) no-repeat center center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: rgba(85, 75, 0, 0.893);
  background-size: cover;
}

.App_header-h1 {
  position: relative;
  top: 2rem;
  left: 12vw;
  /* top: calc(1vh + 2vw); */
  font-family: 'Amatic SC', cursive;
  font-size: 7rem;
  font-size: calc(3rem + 5vw);
  margin-bottom: 0;
}
.App_header-h2 {
  position: relative;
  top: 2rem;
  left: 12vw;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2rem;
  font-size: calc(1rem + 1vw);
  margin-top: .9rem;
}

.media-logo_container {
  position: relative;
  left: 12vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 15rem;
}

.media-logo {
  display: inline-block;
}

.logo {
  display: inline-block;
  height: 35px;
  margin-right: 10px;
  margin-top: 2.2rem;
}

.content_container {
  background-color: #FAFF00;
  padding: 3rem 2rem;
}

@media (max-width: 444px) {
  .App_header-h1 {
    left: 10vw;
  }
  .App_header-h2 {
    left: 10vw;
    margin-top: .5rem;
  }
  .content_container {
    padding: 2rem 0;
  }
}